import { axsWrapper } from './apiUtils'

const axs = axsWrapper('/')

export const getQrSurvey = (slug) => {
  return axs.get(`/QR/feedback/qrinfo/${slug}/`)
}

export const postQrSurvey = (slug, data) => {
  return axs.post(`/QR/feedback/${slug}/`, data)
}

export default axs