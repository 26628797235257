export const maoni1 = {
  "id": 1,
  "name": 'maoni general',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#ffffff',
      "color": "#363A45",
      "accentColor": "#C685FF",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/maoni.png',
            "style": {
              "height": '40px',
            }
          },
          {
            "id": 2,
            "type": "text",
            "label": `Discover Maoni.eu, a modern, QR-code-based solution designed to streamline feedback management for your business. With Maoni, you can effortlessly gather, organize, and analyze customer feedback. Through simplicity, Maoni empowers you to strengthen customer satisfaction, boost loyalty, and drive continuous improvement across your organization.`,
            "style": {
              "textAlign": "justify",
              "fontSize": '13px'
            }
          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "We are eager to hear from you, so don't be shy:",
            "style": {
              "fontSize": '13px',
              "fontWeight": '600'
            }
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Would our tool be simple enough for your customer?",
            "max": 10,
            "style": {
              "fontSize": '13px',
              "fontWeight": '600'
            }
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
            "style": {
              "fontSize": '13px',
              "fontWeight": '600'
            }
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Punch it."
          }
        ]
      }
    ]
  }
}