import React, { useEffect, useState } from "react"
import './CreatorPage.scss'
import PageContainer from '../../../components/page-container/PageContainer'
import Typography from "../../../components/typography/Typography"
import TextLink from "../../../components/buttons/TextLink"
import StyledDropdown from '../../../components/dropdown/StyledDropdown'
import SimpleCard from "../../../components/styled-cards/SimpleCard"
import { NavLink } from "react-router-dom"
import IconButton from "../../../components/buttons/IconButton"
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import PrintIcon from '@mui/icons-material/LocalPrintshopOutlined'
import StyledInputIcon from '../../../components/inputs/StyledInputIcon'
import SearchIcon from '@mui/icons-material/Search'
import StyledModal from "../../../components/modal/StyledModal"
import DeleteQrCode from "./DeleteQrCode"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import PreviewIcon from '@mui/icons-material/Preview'
import MapIcon from '@mui/icons-material/Map'
import QrIcon from '@mui/icons-material/QrCode2'
import PrintFrame from "./PrintFrame"
import { frameSchema } from "../../../mocks/frameSchema"
import QuickFormPreview from "./QuickFormPreview"
import { fullTemplateQuiz } from "../../../mocks/qr-full-template"
import { connect } from 'react-redux'
import { setSuccess, setError } from '../../../redux/ducks/snackbar'
import { Skeleton } from "@mui/material"
import { getCodesList, deleteCode, postCode, putCode, getCode } from "../../../api/qrCodes"
import { getSitesList } from "../../../api/sites"

const CreatorPage = ({ setSuccess, setError }) => {
  const [selectedLocation, setSelectedLocation] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [hasErrorLoadingCodes, setHasErrorLoadingCodes] = useState(false)
  const [isDeletingCode, setIsDeletingCode] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedCode, setSelectedCode] = useState()
  const [isPrinting, setIsPrinting] = useState(false)
  const [isPreviewing, setIsPreviewing] = useState(false)
  const [dropdownLocations, setDropdownLocations] = useState([])
  const [locationsList, setLocationsList] = useState()
  const [hasErrorLoadingSites, setHasErrorLoadingSites] = useState(false)
  const [qrCodes, setQrCodes] = useState()
  const [disableNav, setDisableNav] = useState(false)
  const [disableBtns, setDisableBtns] = useState(false)

  const locFilter = locationsList?.filter((loc, i) => (selectedLocation !== 0 ? loc.id === selectedLocation : loc))

  useEffect(() => {
    getCodesAndLocations()
  }, [])

  const getCodesAndLocations = () => {
    setIsLoading(true)
    getCodesList()
      .then((res) => {
        setQrCodes(res.data)
        return getSitesList()
          .then((res) => {
            setLocationsList(res.data)
            const dropdownLoc = res.data?.map(loc => ({ value: loc.id, item: loc.name }))
            setDropdownLocations(dropdownLoc)
          })
          .catch((err) => {
            setHasErrorLoadingSites(true)
          })
      })
      .catch((err) => {
        setHasErrorLoadingCodes(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getCodes = () => {
    setIsLoading(true)
    getCodesList()
      .then((res) => {
        setQrCodes(res.data)
      })
      .catch((err) => {
        setHasErrorLoadingCodes(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const removeCode = (code) => {
    setDisableNav(true)
    deleteCode(code)
      .then((res) => {
        getCodes()
        closeModal()
        setSuccess('Code deleted successfully!')
      })
      .catch((err) => {
        setError('There was an issue when deleting the code!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const openModal = (i) => {
    setDisableBtns(true)
    setSelectedCode(i)
    setIsOpenModal(true)
  }

  const openRemoveCode = (qr) => {
    openModal(qr)
    setIsDeletingCode(true)
  }

  const openIsPrinting = (qr) => {
    openModal(qr)
    setIsPrinting(true)
  }

  const openIsPreviewing = (qr) => {
    openModal(qr)
    setIsPreviewing(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
    setIsDeletingCode(false)
    setIsPrinting(false)
    setIsPreviewing(false)
    setDisableBtns(false)
    setSelectedCode()
  }

  if (isLoading) return <CodesSkeleton />
  if (hasErrorLoadingCodes || hasErrorLoadingSites) return <ErrorLoadingCodes />

  return (
    <PageContainer className='qr-creator-container'>
      <div className="qr-creator-header">
        <Typography variant='h1'>QR Creator</Typography>
        <Typography variant='subheading3'>
          Here you can edit, remove or add QR codes connected to a location. A location is required before adding a QR code. If you don't have a location yet, please
          <TextLink to='/locations/'>&nbsp;add a location here.</TextLink>
        </Typography>
        <Typography variant='subheading3'>
          For each location, you can see how many codes you have left to add. Purchase more QR codes for specific locations
          <TextLink to='/account?goto=subscription'>&nbsp;by clicking here.</TextLink>
        </Typography>
      </div>
      <div className="qr-top-actions">
        <div className="creator-filters">
          <div className="creator-dropdown">
            <Typography variant='body1'>Select location:</Typography>
            <StyledDropdown
              selected={selectedLocation}
              placeholder='All locations...'
              list={[{ item: 'All locations', value: 0 }, ...dropdownLocations]}
              handleSelectItem={(e) => setSelectedLocation(e)}
            />
          </div>
          <StyledInputIcon
            classname='qr-search'
            placeholder='Search for a QR...'
            icon={<SearchIcon />}
          />
        </div>
        <div className="creator-add-new">
          <NavLink
            to={`/creator/setup/new/`}
          >
            <PrimaryButton>Add code</PrimaryButton>
          </NavLink>
        </div>
      </div>
      <div className="qr-locations-container">
        {locFilter?.map((location, i) => (
          <LocationsRow
            key={`location-row-${location.id}`}
            loc={location}
            qrCodes={qrCodes}
            disableBtns={disableBtns}
            openIsPrinting={openIsPrinting}
            openRemoveCode={openRemoveCode}
            openIsPreviewing={openIsPreviewing}
          />
        ))}
      </div>

      {isOpenModal && <StyledModal
        open={isOpenModal}
        style={{ minHeight: '30%', width: '550px' }}
        onClose={closeModal}
      >
        {isDeletingCode &&
          <DeleteQrCode
            qrCode={selectedCode}
            disableNav={disableNav}
            deleteQrCode={removeCode}
            closeModal={closeModal}
          />}
        {isPrinting &&
          <PrintFrame
            frameSchema={frameSchema}
            closeModal={closeModal}
          />}
        {isPreviewing &&
          <QuickFormPreview
            form={fullTemplateQuiz}
            closeModal={closeModal}
          />}
      </StyledModal>}
    </PageContainer >
  )
}

const LocationsRow = ({ disableBtns, qrCodes, loc, openRemoveCode, openIsPrinting, openIsPreviewing }) => {
  const qrCodesFilter = qrCodes?.filter(qr => qr.site === loc.id)

  return (
    <div className="qr-location">
      <div className="qr-location-header">
        <Typography variant='subheading3'>
          <MapIcon /> {loc?.name}
        </Typography>
        <Typography variant='subheading3'>
          <QrIcon /> {qrCodesFilter?.length}/{loc?.availableCodes || '?'} codes added
        </Typography>
      </div>
      {qrCodesFilter?.length > 0 ?
        <>
          <div className="qr-card-headers">
            <div className="qr-header-descriptors">
              <div className="qr-header-name">
                <Typography variant='subheading3'>Name</Typography>
              </div>
              <div className="qr-header-company">
                <Typography variant='subheading3'>Description</Typography>
              </div>
              <div className="qr-header-codes">
                <Typography variant='subheading3'>Date Added</Typography>
              </div>
            </div>
            <div className="location-card-actions">
              <Typography variant='subheading3'>Actions</Typography>
            </div>
          </div>

          <div className="qr-cards">
            {qrCodesFilter?.map((qr, i) => (
              <QrCodeRow
                key={`qr-${qr.id}`}
                qr={qr}
                disableBtns={disableBtns}
                openIsPrinting={openIsPrinting}
                openRemoveCode={openRemoveCode}
                openIsPreviewing={openIsPreviewing}
              />
            ))}
          </div>
        </>
        :
        <div className="qr-card-none">
          <Typography variant='body1'>No codes found.</Typography>
        </div>
      }
    </div>
  )
}

const QrCodeRow = ({ disableBtns, qr, openRemoveCode, openIsPrinting, openIsPreviewing }) => {
  return (
    <SimpleCard
      className='qr-card'
      key={`qr-${qr.id}`}
      style={{ width: '100%', height: '55px' }}
    >
      <div className="qr-card-descriptors">
        <div className="qr-card-name">
          <Typography variant='body1'>{qr.name}</Typography>
        </div>
        <div className="qr-card-description">
          <Typography variant='body1'>{qr.note || '-no description-'}</Typography>
        </div>
        <div className="qr-card-date">
          <Typography variant='body1'>{qr.date || '10.10.2020'}</Typography>
        </div>
      </div>
      <div className="qr-card-actions">
        <NavLink
          key={`qr-${qr.id}`}
          to={`/creator/setup/${qr.id}/`}
        >
          <IconButton
            title='Edit'
            disabled={disableBtns}
          >
            <EditIcon />
          </IconButton>
        </NavLink>

        <IconButton
          title='Delete'
          disabled={disableBtns}
          onClick={() => openRemoveCode(qr)}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => openIsPrinting(qr)}
          disabled={disableBtns}
          title='Print Frame'
        >
          <PrintIcon />
        </IconButton>
        <IconButton
          onClick={() => openIsPreviewing(qr)}
          disabled={disableBtns}
          title='Preview Form'
        >
          <PreviewIcon />
        </IconButton>
      </div>
    </SimpleCard>
  )
}

const mapDispatchToProps = {
  setError,
  setSuccess
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

export default connectToStore(CreatorPage)

const CodesSkeleton = () => {
  return (
    <PageContainer className='qr-creator-container'>
      <div className="skeleton" style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '30px' }}>
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='80px' />
      </div>
    </PageContainer>
  )
}

const ErrorLoadingCodes = () => {
  return (
    <div className="error-codes">
      Some issue when loading codes - error message.
    </div>
  )
}