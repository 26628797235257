import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "./CheckoutForm"

const stripePromise = loadStripe('STRIPE_PUBLISHABLE_KEY')

const Payment = ({ handlePayment, ...props }) => {
  return (
    <div className="payment-container">
      {/* <Elements stripe={stripePromise}>
        <CheckoutForm handlePayment={handlePayment} />
      </Elements> */}
    </div>
  )
}

export default Payment 